<template>
  <div id="sc-stock-list-filters" class="filter-actions col-lg-12 filters">
    <div class="row">
      <div class="col-lg-3 form-group">
        <label class="control-label">{{$t("stock_management.products.name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('stock_management.products.name')"/>
      </div>
      <div class="col-lg-3 form-group">
        <label class="control-label">{{$t("stock_management.products.barcode")}}</label>
        <input v-model="filters.barcode" class="form-control" :placeholder="$t('stock_management.products.barcode')"/>
      </div>
      <div class="col-lg-3 form-group">
        <label class="control-label">{{$t("stock_management.products.type")}}</label>
        <b-form-select
          v-model="filters.product_type_uuid"
          class="form-control"
          :options="product_type_list"></b-form-select>
      </div>
      <div class="col-lg-3 form-group">
        <label class="control-label">{{$t("common.warehouse")}}</label>
        <b-form-select
          v-model="filters.warehouse_uuid"
          class="form-control"
          :options="warehouse_list"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 mt-3 pt-2">
        <agro-filter @onChange="onDynamicFiltersChanged"/>
      </div>
      <div class="col-lg-2 mt-3 pt-2">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-3 pt-2">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {buildDefaultDropdownList} from "@/helpers/common";
import AgroFilter from "~/components/AgroFilter/Index.vue";

export default {
  name: 'SMStockFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
    }
  },
  components: {
    AgroFilter,
  },
  computed: {
    ...mapGetters({
        gridFilters: 'smStocks/smStockFilters',
        departments: 'company/departments',
        warehouses: 'smWarehouses/smWarehouses',
        productTypes: 'smProductTypes/smProductTypes',
        domain: 'auth/domain',
    }),
    department_list() {
      return buildDefaultDropdownList(Object.assign([], this.departments));
    },
    warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.warehouses), {
      value: 'uuid',
      text: 'name',
      subtext: 'department.name',
    });
    },
    product_type_list() {
      return buildDefaultDropdownList(Object.assign([], this.productTypes));
    },
  },
  methods: {
    ...mapActions({
        getData: 'smStocks/getSmStocks',
        getDepartments: 'company/getDepartments',
        getWarehouses: 'smWarehouses/getSmWarehouses',
        getProductTypes: 'smProductTypes/searchSmProductTypes',
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$emit('onFiltersClickApplied', this.filters);
      this.applyFilters();
    },
    applyFilters() {
      this.$v.filters.$touch();
      if(!this.$v.filters.$invalid) {
        this.$v.filters.$reset();
        this.$emit('onFiltersApplied', this.filters);
        this.getData(this.filters);
      }
    },
    commitFilters() {
      this.$store.commit("smStocks/"+type.SET_SM_STOCK_FILTERS, this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        barcode: null,
        warehouse_uuid: null,
        department_uuid: null,
        product_type_uuid: null,
        page: 1,
        limit: this.limit,
      };

      this.handleWhsFilters();
    },
    handleWhsFilters() {
      this.getWarehouses({
        company_uuid: this.domain.uuid,
        enablePagination: 0,
      }).then((resp) => {
        if(resp.data && resp.data[0]) {
          this.filters.warehouse_uuid = null;
          this.filters.department_uuid = null;
          this.commitFilters();
          this.applyFilters();
        }
      });
    },
    onDepartmentChanged(val) {
      this.getWarehouses({
        company_uuid: this.domain.uuid,
        department_uuid: val,
        enablePagination: 0,
      })
    },
    onDynamicFiltersChanged(selectedUUID) {
      this.filters.department_uuid = selectedUUID
    }
  },
  mounted(){
    this.getDepartments({
      company_uuid: this.domain.uuid,
      enablePagination: 0,
    });

    this.getProductTypes({});
    this.handleWhsFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
    filters: {
      handler: function(newVal) {

      },
      deep: true,
    },
  },
  validations(){
    return {
      filters: {

      }
    }
  }
}
</script>
