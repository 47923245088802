<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('stock_management.stock.title')" id="stock-list">
    <span class="action_buttons">
      <div class="controls text-right pr-3 pt-3">
        <img src="~@/assets/images/desktop/icons/controls/expand.svg" class="icon-svg collapse-icon d-inline-flex"
             :title='$t("toggle_filters")'
             data-toggle="collapse" data-target="#collapseFilters"
             aria-expanded="false" aria-controls="collapseFilters"
        />
      </div>
      <stock-actions></stock-actions>
    </span>
      <div class="row pb-4 collapse show" id="collapseFilters">
        <s-m-stock-filters ref="filters"
                           @onFiltersApplied="onFiltersApplied"
                           @onFiltersClickApplied="onFiltersClickApplied"
        />
      </div>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            :loader="true"
            @onSortBy="onSortBy"
            ref="grid"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import SMStockFilters from "../../stock-management/sm-stocks/partials/smStockFilters.vue";
import StockActions from "~/pages/stock-management/sm-stocks/partials/StockActions.vue";
import Forbidden from "~/pages/errors/Forbidden.vue";

export default {
  name: 'SMStockList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.stockFilters ? this.stockFilters : {},
      items: this.stock ? this.stock : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    Forbidden,
    StockActions,
    SMStockFilters,
    AgroGrid,
  },
  computed: {
    ...mapGetters({
        user: 'auth/user',
        stockFilters: 'smStocks/smStockFilters',
        stock: 'smStocks/smStocks',
        pagination: 'common/pagination',
        users: 'user/users',
    }),
    config() {
        return {
            permission: permissions.SM_PRODUCTS,
            permission_type: permissionTypes.module,
        };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
        return  [
            { name: this.$t('stock_management.products.name'), col: 'product.name', width: '14%', sortable: true, },
            { name: this.$t('stock_management.products.barcode'), col: 'product.barcode', width: '11%'},
            { name: this.$t('stock_management.products.code_short'), col: 'product.code', width: '10%'},
            {
              name: this.$t('stock_management.products.type'),
              col: 'product_type',
              key: 'stock_type',
              width: '12%',
              sortable: true,
              align: 'right'
            },
            { name: this.$t('stock_management.products.quantity'), col: 'quantity_mu', key: 'product.quantity', width: '9%'},            
            { name: this.$t('common.warehouse'), col: 'warehouse_name', align:'right', width: '12%', sortable: true },
            { name: this.$t('common.unit'), col: 'department_name', align:'right', width: '12%', sortable: true },
            { name: this.$t('stock_management.products.partner'), col: 'partner_name', align:'right', width: '11%'},
            { name: this.$t('common.last_update'), col: 'updated_at', align:'right', width: '10%', sortable: true },
        ];
    },
  },
  methods: {
    ...mapActions({

    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("smStocks/"+type.SET_SM_STOCK_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'sm-stock'
      });
    },
    onItemEdited(item) {
        console.log('onItemEdited', item);
    },
    onFiltersApplied(filters) {
        this.loading = true;
    },
    onFiltersClickApplied(filters) {
      this.$set(this, 'items', []);
    },
    onSortBy(data) {
      this.stockFilters.sortBy = data.sortBy;
      this.stockFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    }
  },
  watch: {
    stock: {
      handler: function(newVal) {
        if(this.$refs['grid'].isInfiniteScrollEnabled) {
          newVal.map((item) => {
            this.items.push(item);
          })
        } else {
          this.items = newVal;
        }
        this.loading = false;
      },
      deep: true,
    },
    stockFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  .controls {
    width: 300px;
  }
}
.dropdown {
  position: relative;
  top: -5px;
  margin-right: 20px;
}

.action_buttons {
  position: relative;
  top: -80px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
