<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('stock_management.products.title')" id="bonus-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new")'>
        <s-m-product-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import SMProductFilters from "@/pages/stock-management/sm-products/partials/smProductFilters";
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'SMProductList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.productFilters ? this.productFilters : {},
      items: this.products ? this.products : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    AgroGrid,
    SMProductFilters,
  },
  computed: {
    ...mapGetters({
        user: 'auth/user',
        productFilters: 'smProducts/smProductFilters',
        products: 'smProducts/smProducts',
        pagination: 'common/pagination',
        users: 'user/users',
    }),
    config() {
        return {
            permission: permissions.SM_PRODUCTS,
            permission_type: permissionTypes.module,
            edit: {
              url: '/stock-management/sm-products/{uuid}',
              callback: this.onItemEdited,
            },
            delete: {
                method: this.deleteObject,
                callback: this.onItemDeleted,
                disableIf: this.disableDeleteIf,
                disableMessage: this.$t('stock_management.products.cannot_be_deleted')
            }
        };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
        return  [
            { name: this.$t('stock_management.products.name'), col: 'name', width: '19%', sortable: true, },
            { name: this.$t('stock_management.products.barcode'), col: 'barcode', width: '14%'},
            { name: this.$t('stock_management.products.code_short'), col: 'code', width: '14%'},
            {
              name: this.$t('stock_management.products.type'),
              col: 'product_type.name',
              key: 'product_type',
              width: '14%',
              sortable: true,
              align: 'left'
            },
            { name: this.$t('common.measure_unit'), col: 'measure_unit.name', align:'left', width: '9%', sortable: true },
            { name: this.$t('common.created_at'), col: 'created_at', align:'left', width: '12%', sortable: true },
            { name: this.$t('common.last_update'), col: 'updated_at', align:'left', width: '12%', sortable: true },
        ];
    },
  },
  methods: {
    ...mapActions({
      deleteObject: 'smProducts/deleteSmProduct', //check to delete if has stock
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("smProducts/"+type.SET_SM_PRODUCT_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'sm-products/add'
      });
    },
    onItemEdited(item) {
        console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      //check if has stock
        this.items.splice(
          this.items.indexOf(
            this.items.find(it => it.uuid === item.uuid)
          ), 1
        );
    },
    disableDeleteIf(row) {
        return !row.is_editable;
    },
    onFiltersApplied(filters) {
        this.loading = true;
    },
    onSortBy(data) {
      this.productFilters.sortBy = data.sortBy;
      this.productFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    }
  },
  watch: {
    products: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
    productFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
