<template>
  <div>
  <div>
  <img v-if="header.cancelDocument && !data.canceled_at" 
    :src="require('@/assets/images/desktop/icons/controls/delete.svg')"
    :class="['edit', 'secondary-filter']" 
    :title="'Cancel ' + data.type_name.toLowerCase()"
    class="action-icon" 
    @click="confirmCancel" 
    width="25" 
    height="25" 
    style="cursor: pointer;" />
  <pdf-download-col :data="exported_data" :header="header" v-else-if="exported_data"></pdf-download-col>
  <img v-else-if="(!header.cancelDocument && !!data.export_url) || (header.cancelDocument && !!data.document && !!data.cancel_export_url)"
    :src="require('@/assets/images/icons/transfer.svg')"
    :class="['edit', 'primary-filter']" 
    :title="name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()"
    class="action-icon" 
    @click="onClick" 
    width="25" 
    height="25" 
    style="cursor: pointer;" />
    <div v-else style="width: 25px;"></div>
  </div>
    <DeleteConfirmationDialog
      :show="showDeleteConfirmationModal"
      @close="showDeleteConfirmationModal = false"
      @confirm="onClickCancelTransaction"
      :title="$t('stock_management.title')"
      :message="$t('stock_management.transactions.delete_question')"
    ></DeleteConfirmationDialog>
  </div>
</template>

<script>
import { errorMessage } from "~/helpers/common";
import PdfDownloadCol from "~/pages/stock-management/sm-transactions/partials/Cols/PdfDownloadCol.vue";
import { mapActions } from 'vuex'
import { errorMessageList } from "~/helpers/common";
import DeleteConfirmationDialog from "@/components/Modals/DeleteConfirmationDialog";

export default {
  components: { PdfDownloadCol, DeleteConfirmationDialog },
  props: ['data', 'header'], //transaction
  name: 'PdfExportCol',
  data() {
    return {
      exported_data: null,
      showDeleteConfirmationModal: false,
    }
  },
  computed: {
    name() {
      return this.header.cancelDocument ? this.$t('stock_management.transactions.generate_cancel_pdf', { type: this.data.type_name }) : this.$t('stock_management.transactions.generate_pdf', { type: this.data.type_name });
    },
  },
  methods: {
    ...mapActions({
      cancelSmTransaction: 'smTransactions/cancelSmTransaction',
    }),
    onClick() {
      let vm = this;
      this.$store.dispatch('smTransactions/getExportInfo', {
        type: this.data.transaction_type,
        url: this.header.cancelDocument ? this.data.cancel_export_url : this.data.export_url,
        response_type: 'application/json'
      }).then((resp) => {
        vm.exported_data = resp.data.transaction;
        this.$store.dispatch('smTransactions/downloadPdf', {
          type: resp.data.transaction_type,
          url: resp.data.download_url,
          filename: resp.data.download_filename,
          response_type: 'blob'
        });
      }).catch((e) => {
        console.log('check export error: ', e.response);
        if (e.response.status === 400 || e.response.status === 500) {
          if (e.response.data.errors && e.response.data.errors.error) {
            errorMessage(vm, e.response.data.errors.error);
          } else {
            errorMessage(vm, vm.$t('error_alert_text'));
          }
        }
      }); //after?
    },
    async confirmCancel() {
      if (this.header.cancelDocument && !this.data.canceled_at) {
        this.showDeleteConfirmationModal = true;
      }
    },
    onClickCancelTransaction() {
      this.showDeleteConfirmationModal = false;
      let vm = this;
      if (this.header.cancelDocument && !this.data.canceled_at) {
        this.cancelSmTransaction({
          uuid: this.data.uuid,
        }).then((response) => {
          this.data.cancelDocument = response.data.cancelDocument;
          this.data.canceled_at = response.data.canceled_at;
          this.data.cancel_export_url = response.data.cancel_export_url;
        }).catch((e) => {
          if (e.response.status === 400 || e.response.status === 500) {
            if (e.response.data.errors && e.response.data.errors.error) {
              errorMessage(vm, e.response.data.errors.error);
            } else {
              errorMessageList(vm, e.errors);
            }
          }
        });
      }
    },
  },
}
</script>
