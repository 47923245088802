<template>
    <DxTabPanel
        :element-attr="tabPanelAttributes"
        :data-source="transferSteps"
        :loop="false"
        :selected-item="currentItem"
        :selected-index="currentStep"
        :animation-enabled="true"
        :swipe-enabled="false"
        :show-nav-buttons="false"
        @selection-changed="tabSelectionChanged($event)"
    >
        <template #item="{ data: step }">
            <div>
                <DxScrollView ref="transferInputScroll" v-show="step == 1" class="transfer-input-scroll" :use-native="true">
                    <div class="transfer_input_holder"
                            :class="[{input_holder_error: $v.transferData.transfer_date.$error}]">               
                        <div @click="showCalendarSelector">
                            <div class="input_label">Data</div>
                            <div class="input_value">
                                {{ transferData.transfer_date }}
                            </div>
                        </div>
                        <div v-if="is_date_selector_visible">
                            <FunctionalCalendar
                                :date-format="'yyyy-mm-dd'"
                                :is-date-range="false"
                                v-model="calendarData"
                                :change-month-function="true"
                                :change-year-function="true"
                                :is-date-picker="true"
                                @choseDay="calendarDayChosen"
                                @input="onDateSelected"
                            ></FunctionalCalendar>
                        </div>
                    </div>
                    <div class="transfer_input_holder"
                        :class="[{input_holder_error: $v.transferData.product_uuid.$error}]">
                        <div class="input_label">Produs</div>
                        <div class="input_value" >
                            <DxSelectBox
                                v-model="transferData.product_uuid"
                                :search-enabled="true"
                                search-mode="contains"
                                search-expr="name"
                                class="input_value dx_input_value"
                                placeholder="Alegeți produs"
                                :data-source="products"
                                display-expr="name"
                                value-expr="uuid"
                                :show-clear-button="false"
                                drop-down-button-template="imageIcon"
                                @selection-changed="productChanged"
                                >
                                    <template #imageIcon>
                                    <div>
                                        <div class="dx_combo_open_button" v-show="!transferData.product_uuid || transferData.product_uuid == ''">
                                            <img src="@/assets/images/icons/plus_green.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                        <div class="dx_combo_open_button" v-show="transferData.product_uuid && transferData.product_uuid.length > 0">
                                            <img src="@/assets/images/icons/edit.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                    </div>
                                </template>
                                <template #item="{data}">
                                    <div class="dx_custom_combo_item">{{data.name}}</div>
                                </template>
                            </DxSelectBox>
                        </div>
                        <div v-show="availableQuantity > 0" class="transfer_input_second_value">{{availableQuantity}}{{selected_mu_symbol}}</div>
                    </div>
                    <div class="transfer_input_holder"
                        :class="[{input_holder_error: $v.transferData.warehouse_uuid.$error}]">
                        <div class="input_label">Depozit expeditor</div>
                        <div class="input_value">
                            <DxSelectBox
                                v-model="transferData.warehouse_uuid"
                                :search-enabled="false"
                                search-mode="contains"
                                search-expr="name"
                                class="input_value dx_input_value"
                                placeholder="Alegeți depozit expeditor"
                                :data-source="warehouses"
                                display-expr="name"
                                value-expr="uuid"
                                :show-clear-button="false"
                                drop-down-button-template="imageIcon"
                                @selection-changed="warehouseChanged"
                                >
                                    <template #imageIcon>
                                    <div>
                                        <div class="dx_combo_open_button" v-show="!transferData.warehouse_uuid || transferData.warehouse_uuid == ''">
                                            <img src="@/assets/images/icons/plus_green.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                        <div class="dx_combo_open_button" v-show="transferData.warehouse_uuid && transferData.warehouse_uuid.length > 0">
                                            <img src="@/assets/images/icons/edit.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                    </div>
                                </template>
                                <template #item="{data}">
                                    <div class="dx_custom_combo_item">{{data.name}} - {{data.quantity}}{{data.mu}}</div>
                                </template>
                                </DxSelectBox>
                        </div>
                    </div>
                    <div class="transfer_input_holder"
                        :class="[{input_holder_error: $v.transferData.quantity.$error}]">
                        <div class="input_label">Cantitate</div>
                        <div class="input_value">
                            <DxNumberBox
                                placeholder="Introduceți cantitatea"
                                :min="0"
                                :format="'#0.## ' + selected_mu_symbol"
                                v-model="transferData.quantity"
                                class="global_dx_number_input"
                                :show-clear-button="false"
                            /></div>
                    </div>

                    <div class="transfer_input_holder"
                        :class="[{input_holder_error: $v.transferData.dest_warehouse_uuid.$error}]">
                        <div class="input_label">Depozit receptor</div>
                        <div class="input_value">
                            <DxSelectBox
                                v-model="transferData.dest_warehouse_uuid"
                                :search-enabled="false"
                                search-mode="contains"
                                search-expr="name"
                                class="input_value dx_input_value"
                                placeholder="Alegeți depozit receptor"
                                :data-source="destWarehouses.filter(w => !w.is_source)"
                                display-expr="name"
                                value-expr="uuid"
                                :show-clear-button="false"
                                drop-down-button-template="imageIcon"
                                >
                                    <template #imageIcon>
                                    <div>
                                        <div class="dx_combo_open_button" v-show="!transferData.dest_warehouse_uuid || transferData.dest_warehouse_uuid == ''">
                                            <img src="@/assets/images/icons/plus_green.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                        <div class="dx_combo_open_button" v-show="transferData.dest_warehouse_uuid && transferData.dest_warehouse_uuid.length > 0">
                                            <img src="@/assets/images/icons/edit.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                    </div>
                                </template>
                                <template #item="{data}">
                                    <div class="dx_custom_combo_item">{{data.name}}</div>
                                </template>
                                </DxSelectBox>
                        </div>
                    </div>
                    <div class="transfer_input_holder">
                        <div class="input_label">Observații</div>
                        <div class="input_value">
                             <DxTextArea v-model="transferData.notes" class="global_dx_number_input" />
                        </div>
                     </div>
                    <div class="input-bottom-spacer"></div>
                </DxScrollView>
                 <div class="transfer_bottom_sticky_two_line" v-show="step == 1 && showSaveButtons">
                    <div class="sticky_action_button" @click="saveTransfer(true)">Salvează și transfer nou</div>
                    <div class="sticky_action_button_second" @click="saveTransfer(false)">Salvează</div>
                </div>
                <toast-message ref="toastMessage"></toast-message>
            </div>
        </template>

     </DxTabPanel>
</template>

<script>

import { mapActions } from 'vuex'
import DxTabPanel from "devextreme-vue/tab-panel";
import { required, helpers } from "vuelidate/src/validators";
import DxNumberBox from "devextreme-vue/number-box";
import DxTextBox from 'devextreme-vue/text-box';
import DxComboWithAdd from "@/components/Mobile/DxComboWithAdd/index.vue";
import { v4 } from 'uuid';
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxSelectBox from "devextreme-vue/select-box";
import ToastMessage from "@/components/Mobile/toastMessage.vue"
import DxTextArea from 'devextreme-vue/text-area';
import { FunctionalCalendar } from "vue-functional-calendar";
import moment from "moment";

const greaterThanZero = (value) => {
   return helpers.req(value) && value > 0;
};

const requiredByParam = (param) => (value) => {
    return helpers.req(value) && value <= param;
};

export default {
    name: "Transfer",
    data(){
        return {
            calendarData: {},
            tabPanelAttributes: {
                class: 'transfer_tabs'
            },
            transferSteps: [1],
            currentStep: 0,
            currentItem: 1,
            selected_mu_symbol: '',
            transferData: {
                warehouse_uuid: '',
                department_uuid: '',
                dest_warehouse_uuid: '',
                product_uuid:'',
                product_name: '',
                quantity: null,
                notes: '',
                transfer_date: null
            },
            products: [],
            warehouses: [],
            destWarehouses: [],
            availableQuantity: 0.00,
            selected_mu_symbol: '',
            is_date_selector_visible: false
        };
    },
    components: {
        DxTabPanel, DxNumberBox,
        DxTextBox,
        DxComboWithAdd,
        DxScrollView,
        DxSelectBox,
        ToastMessage,
        DxTextArea,
        FunctionalCalendar
    },
    computed: {
        showSaveButtons(){
            return (this.transferData.product_uuid && this.transferData.product_uuid.length > 0) ||
            (this.transferData.warehouse_uuid && this.transferData.warehouse_uuid.length > 0) ||
            (this.transferData.quantity && this.transferData.quantity > 0) ||
            (this.transferData.dest_warehouse_uuid && this.transferData.dest_warehouse_uuid.length > 0);
        },
    },
    validations() {
        return {
            transferData:{
                warehouse_uuid: {required},
                dest_warehouse_uuid: {required},
                product_uuid: {required},
                quantity: {requiredByParam:requiredByParam(this.availableQuantity)},
                transfer_date: {required}
            }
        };
    },
    methods: {
         ...mapActions({
            getProducts: 'smProducts/getSmProducts',
            getWarehouses: 'smWarehouses/getSmWarehouses',
            createTransfer: 'smTransactions/createTransferTransaction'
        }),
         tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.transferSteps.indexOf(this.currentItem);
          this.changeAddButtonVisibility();
        },
        changeAddButtonVisibility(){
            this.$emit('changeAddButtonVisibility', this.currentStep == 1);
        },
         goForward(){
            this.currentStep = this.currentStep + 1;
            this.setTitle();
        },
         setTitle(){
            if(this.currentStep == 0) {
                this.$emit('setTitle', 'Transfer produs', true);
            }
        },
         goBack(){
            if(this.currentStep > 0){
                this.currentStep = this.currentStep - 1;
                this.setTitle();
            }
            else{
                this.$emit('goBackParent');
            }
        },
        saveTransfer(saveAndNew){
            this.$v.transferData.$touch();
            if (!this.$v.transferData.$invalid) {
                let transferDataForSave = {
                    warehouse_uuid: this.transferData.warehouse_uuid,
                    department_uuid: this.getWarehouseDepartmentByWarehouseUuid(this.transferData.warehouse_uuid),
                    dest_warehouse_uuid: this.transferData.dest_warehouse_uuid,
                    dest_department_uuid: this.getWarehouseDepartmentByWarehouseUuid(this.transferData.dest_warehouse_uuid),
                    product: {
                        uuid: this.transferData.product_uuid,
                        name: this.transferData.product_name,
                        quantity: this.transferData.quantity
                    },
                    notes: this.transferData.notes,
                    transfer_date: this.transferData.transfer_date
                };
                this.createTransfer(transferDataForSave).then(response => {
                    this.$refs.toastMessage.showSuccess('Transferul a fost salvat cu succes!');
                    if(saveAndNew){
                        this.resetForm();
                    }
                    else{
                        this.closeTransactions('Transferul a fost salvat cu succes!');
                    }
                })
                .catch((e) => {
                    this.$refs.toastMessage.showError('A apărut o erroare la salvarea transferului. Contactați administratorul de sistem!');
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createErrorMessages());
            }
        },
        productChanged(e){
            this.selected_mu_symbol = e.selectedItem ? e.selectedItem.measure_unit.symbol : '';
            this.transferData.product_name = e.selectedItem ? e.selectedItem.name : '';
            if(e.selectedItem){
                this.selected_mu_symbol = e.selectedItem.measure_unit.symbol;
                this.warehouses = [];
                if(e.selectedItem.stock_list && e.selectedItem.stock_list.length > 0){
                    e.selectedItem.stock_list.forEach(s => {
                        if(s.quantity > 0){
                            this.warehouses.push({
                                uuid: s.warehouse_uuid,
                                name: s.warehouse_name,
                                quantity: s.quantity,
                                mu: s.measure_unit
                            });
                        }
                    });
                }
            }
        },
        warehouseChanged(e){
            if(e.selectedItem){
                this.availableQuantity = e.selectedItem.quantity;
                this.destWarehouses.forEach(w => {
                    w.is_source = w.uuid == e.selectedItem.uuid;
                })
            }
        },
        resetForm(){
            this.$v.$reset();
            this.availableQuantity = 0.00;
            this.transferData = {
                warehouse_uuid: '',
                department_uuid: '',
                dest_warehouse_uuid: '',
                product_uuid:'',
                product_name: '',
                quantity: null,
                notes: ''
            };
            this.loadProducts();
        },
        closeTransactions(message){
            this.$emit('closeTransactions', message);
        },
        loadProducts(){
            this.getProducts({limit: 999999, enablePagination: false, has_stock: 1}).then(response => {
                this.products = response.data;
            });
        },
        createErrorMessages(){
            let errorMessages = [];

            if(this.$v.transferData.product_uuid.$invalid){
                errorMessages.push("Produsul este obligatoriu!");
            }
            if(this.$v.transferData.warehouse_uuid.$invalid){
                errorMessages.push("Depozitul expeditor este obligatoriu!");
            }
            if(this.$v.transferData.dest_warehouse_uuid.$invalid){
                errorMessages.push("Depozitul receptor este obligatoriu!");
            }
             if(this.$v.transferData.quantity.$invalid){
                if(this.availableQuantity > 0){
                    errorMessages.push("Cantitatea trebuie să fie intre 0 și " + this.availableQuantity + "!");
                }
                else{
                    errorMessages.push("Cantitatea trebuie să fie mai mare de 0!");
                }
            }

            return errorMessages;
        },
        showCalendarSelector(){
            this.is_date_selector_visible = !this.is_date_selector_visible;
        },
        calendarDayChosen(data){
            this.is_date_selector_visible = false;
        },
        onDateSelected(date) {
            this.transferData.transfer_date = date.selectedDate;
        },
        getWarehouseDepartmentByWarehouseUuid(warehouseUuid){
            let warehouse = this.warehouses.find(w => w.uuid == warehouseUuid);
            if(warehouse){
                return warehouse.department_uuid;
            }
            return null;
        }
    },
    mounted(){
        this.transferData.transfer_date = moment().format('YYYY-MM-DD');
        this.setTitle();
        this.getWarehouses({}).then(response => {
             response.data.forEach(w => {
                w.is_source = false;
                this.destWarehouses.push(w);
             });
        });
        this.loadProducts();
    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/stockMobile/transfer.scss";
</style>
